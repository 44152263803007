<!--
 * @Author: wyq
 * @Date: 2021-06-08 15:00:53
 * @LastEditTime: 2021-06-11 17:07:08
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\views\home.vue
-->
<template>
  <div class="page-home">
    <div ref="katex">题目如下$\frac{x^{2}}{9}+\frac{y^{2}}{6}=1$</div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$formula(this.$refs['katex'])
  },
}
</script>
<style lang="scss" scoped>
.page-home {
  font-size: 14px;
  height: 2000px;
}
</style>
  